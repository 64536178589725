import { Outlet } from "react-router-dom";
import Footer from "./footer";
import TopNav from "./top-nav";

function EmptyLayout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default EmptyLayout;
