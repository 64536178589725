import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useAuth from "../utils/context/auth-context";

export function AuthGuard({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();
  const { auth } = useAuth();

  const authToken = localStorage.getItem("_chuck-38e6e39a-ab69-4aac-bb0c");

  if (!authToken) return <Navigate to="/auth/login" replace />;

  // useEffect(() => {
  //   if (!auth) {
  //     navigate("/auth/login");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [auth]);

  return children;
}
export function ReversGuard({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const authToken = localStorage.getItem("_chuck-38e6e39a-ab69-4aac-bb0c");

  if (authToken) return <Navigate to="/profile" replace />;
  // useEffect(() => {
  //   if (auth) {
  //     navigate("/profile");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [auth]);

  return children;
}
